import * as React from 'react';
import classNames from 'clsx';
import { TransitionGroup } from 'react-transition-group';
import Transition from '../../Transition/Transition';
import { SlidesProps } from '../SlideShowContainer.types';
import { TestIds } from '../constants';
import styles from './style/SlideShowContainer.scss';

const Slides: React.ForwardRefRenderFunction<HTMLDivElement, SlidesProps> = (
  {
    isPlaying,
    isSlideShowInViewport,
    reverse,
    transition,
    transitionDuration,
    currentSlideIndex,
    onSlideEntered,
    onSlideExited,
    children,
    dynamicHeight,
    inTransition,
  },
  ref,
) => {
  const ariaLive =
    !isSlideShowInViewport || (isPlaying && isSlideShowInViewport)
      ? 'off'
      : 'polite';

  const isTransitionOverflowHidden =
    transition === 'SlideVertical' || transition === 'SlideHorizontal';

  return transition === 'NoTransition' ? (
    <div
      ref={ref}
      data-testid={TestIds.slidesWrapper}
      className={classNames(
        styles.slides,
        dynamicHeight ? styles.slidesDynamicHeight : undefined,
      )}
      aria-live={ariaLive}
    >
      {children}
    </div>
  ) : (
    <div
      ref={ref}
      data-testid={TestIds.slidesWrapper}
      aria-live={ariaLive}
      className={classNames(
        styles.slides,
        dynamicHeight ? styles.slidesDynamicHeight : undefined,
        inTransition && isTransitionOverflowHidden
          ? styles.inTransition
          : undefined,
      )}
    >
      <TransitionGroup
        component={null}
        childFactory={child => React.cloneElement(child, { reverse })}
      >
        <Transition
          type={transition}
          key={currentSlideIndex}
          timeout={transitionDuration}
          onEntered={onSlideEntered}
          onExited={onSlideExited}
          unmountOnExit
        >
          {children}
        </Transition>
      </TransitionGroup>
    </div>
  );
};

export default React.forwardRef(Slides);
